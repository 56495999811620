<template>
  <button
    class="focus-default group flex items-center gap-3 text-xs sm:text-sm"
    @click="scrollTo()"
  >
    <ScrollyIcon class="w-3 animate-custom-bounce sm:w-[0.875rem]" />
    <span>
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  methods: {
    scrollTo() {
      const selector = '[component-index="1"]'
      const element = document.querySelector(selector)

      if (!element) {
        return
      }

      const headerOffset = 96
      const elementPosition = element?.getBoundingClientRect().top
      const offsetPosition = elementPosition - headerOffset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    },
  },
}
</script>
